import { useEffect, useState } from 'react';

import useUserRoles, { RoleType } from './useUserRoles';

export default function useSelectedUserRole() {
  const [selectedRole, setSelectedRole] = useState<RoleType | null>(null);
  const { roles } = useUserRoles();

  useEffect(() => {
    const local_selected_role = localStorage.getItem('selected-role');
    if (local_selected_role) {
      try {
        const parsed_role = JSON.parse(local_selected_role);
        const matchedRoleObj = roles.find((role) => role.id == parsed_role);
        if (matchedRoleObj) {
          setSelectedRole(matchedRoleObj);
        }
      } catch (err) {
        console.log('error');
      }
    } else {
      // set default available role
      if (roles && roles[0]) {
        setSelectedRole(roles[0]);
        localStorage.setItem('selected-role', JSON.stringify(roles[0].id));
      }
    }
  }, [roles]);

  const updateSelectedRole = (role: string) => {
    localStorage.setItem('selected-role', JSON.stringify(role));

    setTimeout(() => {
      window.location.reload();
    });
  };

  return { selectedRole, updateSelectedRole };
}
