'use client';

import { Button } from '@chakra-ui/react';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

export default function AuthButton() {
  const { t } = useTranslation();
  const router = useRouter();
  const { data: session } = useSession();

  const handleSignOut = async (e) => {
    e.preventDefault();
    try {
      await signOut({ callbackUrl: '/', redirect: true });
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('token_expires_in');
      localStorage.removeItem('roles');
      localStorage.removeItem('roleId');
      localStorage.removeItem('user');
      localStorage.removeItem('selected-role');
    } catch (error) {
      console.log('errors', error);
    }
  };

  if (session) {
    return (
      <Link
        href={'/api/auth/signout'}
        prefetch={false}
        onClick={handleSignOut}
        style={{ lineHeight: 'unset', display: 'flex', width: '100%' }}
      >
        <Button
          variant="solid"
          colorScheme="#182868;"
          size="sm"
          width="100%"
          // onClick={handleSignOut}
        >
          {t('menu.topbar.logout')}
        </Button>
      </Link>
    );
  }
  return (
    <Button
      variant="solid"
      colorScheme="#182868;"
      size="sm"
      onClick={() => router.push('/')}
    >
      {t('menu.topbar.login')}
    </Button>
  );
}
