export const userRolesObj = {
  merchant_admin: 'merchant_admin',
  kevytyrittäjä: 'kevytyrittäjä',
  tarkastaja: 'tarkastaja',
  kirjanpitäjä: 'kirjanpitäjä',
};
//   kevytyrittäjä: '', //normal user // can only cretae invoices or costs or travel to himself) so on flow we need to disable "select user" menu
//   tarkastaja: '', // inspector // can only see stuff, but cannot edit or create anything
//   Toimeksiantaja: '',
//   Yleinen: '',
//   Asiakaspalvelija: '',
//   kirjanpitäjä: '', // accountant  // can do all except add new users
//   merchant_admin: '', // admin
