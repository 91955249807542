import { userRolesObj } from '@/lib/getUserRoles';
import useSelectedUserRole from './useSelectedUserRole';
import { useEffect, useState } from 'react';

export default function useIsAccountantRole() {
  const { selectedRole } = useSelectedUserRole();
  const [isAccountantRole, setIsAccountantRole] = useState(false);

  useEffect(() => {
    // kirjanpitäjä = accountant
    if (userRolesObj.kirjanpitäjä == selectedRole?.name) {
      setIsAccountantRole(true);
    }
  }, [selectedRole]);

  return { isAccountantRole };
}
