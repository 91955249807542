import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/layout/NewLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fontsource/poppins/400.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/components/rc-tree.css");
;
import(/* webpackMode: "eager" */ "/app/layout/MainCSS.css");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContainer"] */ "/app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
