import { useEffect, useState } from 'react';

export type RoleType = {
  merchant: string;
  id: string;
  name: string;
  display_name: string;
  display_name__en: string;
  merchant_name: string;
  application: string;
  application_name: string;
  application_classname: string;
  customers: string[];
};

export default function useUserRoles() {
  const [roles, setRoles] = useState<Array<RoleType>>([]);

  useEffect(() => {
    const local_roles = localStorage.getItem('roles');
    if (local_roles) {
      try {
        const parsed_roles = JSON.parse(local_roles);
        setRoles(parsed_roles);
      } catch (err) {
        console.log('error');
      }
    }
  }, []);

  return { roles };
}
