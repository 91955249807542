import { userRolesObj } from '@/lib/getUserRoles';
import useSelectedUserRole from './useSelectedUserRole';
import { useEffect, useState } from 'react';

export default function useIsAdminRole() {
  const { selectedRole } = useSelectedUserRole();
  const [isAdminRole, setIsAdminRole] = useState(false);

  useEffect(() => {
    if (userRolesObj.merchant_admin == selectedRole?.name) {
      setIsAdminRole(true);
    }
  }, [selectedRole]);

  return { isAdminRole };
}
