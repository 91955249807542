import { extendTheme } from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";

export const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        background: mode('#f0f0f0', '#f0f0f0'),
      },
    }),
  },
  fonts: {
    heading: '\'Poppins\', Arial, sans-serif',
    body: '\'Poppins\', Arial, sans-serif',
  },
  colors: {
    red: {
      50: '#FBE9E8', 100: '#FFCCBE', 200: '#FFAB95', 300: '#FF8A6A', 400: '#FF6F4A', 500: '#FF542C', 600: '#F95028', 700: '#EB4723', 800: '#DD401F', 900: '#C33416',
    },
    yellow: {
      50: '#FFFCE7', 100: '#FFF5C5', 200: '#FFEF9F', 300: '#FFE979', 400: '#FEE45A', 500: '#FCDE3D', 600: '#FCCE3B', 700: '#F8B735', 800: '#F49F2F', 900: '#EE7724',
    },
    blue: {
      50: '#E7EAF3', 100: '#C3CAE2', 200: '#9CA8CE', 300: '#7687BB', 400: '#586DAD', 500: '#3A54A0', 600: '#344C97', 700: '#2B428B', 800: '#24397F', 900: '#182868',
    },
    green: {
      50: '#DAF5F0', 100: '#A4E6D6', 200: '#5ED6BB', 300: '#00C3A1', 400: '#00B38E', 500: '#00A47C', 600: '#00966F', 700: '#00865F', 800: '#007551', 900: '#005835',
    },
  },
});
