'use client';

import type { MenuItem } from '@/app/menuitems';
import { menuItems, topMenuItems } from '@/app/menuitems';
import { ExitImpersonate } from '@/components/ActionIcons';
import { Image } from '@chakra-ui/next-js';
import {
  Box,
  Center,
  Collapse,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Select,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
  useTheme,
} from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faEllipsis,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthButton from '../components/auth-btn';
import { FooterBar } from './FooterBar';
import { FaRegUserCircle } from 'react-icons/fa';
import useUserRoles from '../hooks/useUserRoles';
import useSelectedUserRole from '../hooks/useSelectedUserRole';
import useIsAdminRole from '../hooks/useIsAdminRole';
import useIsAccountantRole from '../hooks/useIsAccountantRole';
import useIsUserNormalRole from '../hooks/useIsUserNormalRole';

const MenuIcon = () => <Icon as={() => <FontAwesomeIcon icon={faBars} />} />;
const CloseIcon = () => <Icon as={() => <FontAwesomeIcon icon={faXmark} />} />;
const DotsMenuIcon = () => <Icon as={FontAwesomeIcon} icon={faEllipsis} />;

const SidebarItem = ({ item }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { roles } = useUserRoles();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const [isadmin, setisadmin] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let local_selected_role = localStorage.getItem('selected-role');
    const local_roles = localStorage.getItem('roles');
    let parsed_roles = [];

    if (local_roles) {
      try {
        parsed_roles = JSON.parse(local_roles);
        local_selected_role = JSON.parse(
          local_selected_role ? local_selected_role : ''
        );

        const role = parsed_roles.find(
          (role) => role.id == local_selected_role
        );
        console.log({ role }, local_selected_role);
        if ('merchant_admin' == role?.name) {
          setisadmin(true);
        }
      } catch (err) {
        console.log('error');
      }
    }
  }, []);

  useEffect(() => {
    const new_items =
      item?.children?.filter((item) => {
        console.log({ good: item.tnKey });
        if (item.tnKey === 'menu.sidebar.materialbank') {
          return isadmin;
        }

        return true;
      }) ?? [];
    setItems(new_items);
  }, [isadmin, item]);

  if (item.type === 'divider') {
    return <Divider borderColor="gray.200" />;
  }

  if (item.type === 'header') {
    return (
      <Heading
        as="h2"
        color="black"
        fontSize="xs"
        mt="4"
        mb="4"
        textTransform="uppercase"
      >
        {t(item.tnKey)}
      </Heading>
    );
  }

  if (item.children) {
    return (
      <Box w="100%">
        <ListItem cursor="pointer" onClick={handleToggle}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              {item.icon && <Icon as={FontAwesomeIcon} icon={item.icon} />}
              <Text ml="2">{t(item.tnKey)}</Text>
            </Flex>
            {isOpen ? (
              <Icon as={FontAwesomeIcon} icon={faAngleUp} />
            ) : (
              <Icon as={FontAwesomeIcon} icon={faAngleDown} />
            )}
          </Flex>
        </ListItem>
        <Collapse in={isOpen}>
          <List ml="4" styleType="none" mt={4}>
            {items.map((childItem) => (
              <SidebarItem key={t(childItem.tnKey)} item={childItem} />
            ))}
          </List>
        </Collapse>
      </Box>
    );
  }

  return (
    <ListItem cursor="pointer" mb="2">
      <Link href={item.path} style={{ textDecoration: 'none' }}>
        <Flex alignItems="center">
          {item.icon && <Icon as={FontAwesomeIcon} icon={item.icon} />}
          <Box ml="1">{t(item.tnKey)}</Box>
        </Flex>
      </Link>
    </ListItem>
  );
};

const SidebarMenu = ({ menuItems }) => {
  const { data: session } = useSession();
  const userRole = session?.user?.role;
  const { isAdminRole } = useIsAdminRole();
  const { isAccountantRole } = useIsAccountantRole();
  const { isUserNormalRole } = useIsUserNormalRole();

  const filteredMenuItems = menuItems
    .filter((item) => {
      console.log(item);
      // If no permissions are specified, show the item to all users
      if (!item.permissions) return true;

      // Check if the user's role is included in the item's permissions
      return item.permissions.includes(userRole);
    })
    .filter((item) => {
      if (item.type == 'divider') {
        if (!isAdminRole) return true;
        return false;
      }
      if (item.tnKey == 'menu.sidebar.allSalaries') {
        if (isAccountantRole) return true;
        return false;
      }
      if (item.tnKey == 'menu.sidebar.balance') {
        if (isUserNormalRole) return true;
        return false;
      }

      if (item.tnKey == 'menu.sidebar.withdrawSalary') {
        if (isUserNormalRole || isAccountantRole) return true;
        return false;
      }

      if (
        item.tnKey == 'menu.sidebar.sales' ||
        item.tnKey == 'menu.sidebar.invoices' ||
        item.tnKey == 'menu.sidebar.myProfile' ||
        item.tnKey == 'menu.sidebar.personalInformation' ||
        item.tnKey == 'menu.sidebar.taxCard' ||
        item.tnKey == 'menu.sidebar.yelInsurances' ||
        item.tnKey == 'menu.sidebar.expensesAndTrips' ||
        item.tnKey == 'menu.sidebar.travelAllowances' ||
        item.tnKey == 'menu.sidebar.allTravelAllowances' ||
        item.tnKey == 'menu.sidebar.newExpenseAndTrip' ||
        item.tnKey == 'menu.sidebar.purchaseInvoice' ||
        item.tnKey == 'menu.sidebar.allPurchaseAllowances' ||
        item.tnKey == 'menu.sidebar.newPurchaseInvoice' ||
        item.tnKey == 'menu.sidebar.salaries' ||
        item.tnKey == 'menu.sidebar.allSalaries' ||
        item.tnKey == 'menu.sidebar.balance' ||
        item.tnKey == 'menu.sidebar.reports' ||
        item.tnKey == 'menu.sidebar.reportbalance' ||
        item.tnKey == 'menu.sidebar.sales' ||
        item.tnKey == 'menu.sidebar.archive' ||
        item.tnKey == 'menu.sidebar.others' ||
        item.tnKey == 'menu.sidebar.clients' ||
        item.tnKey == 'menu.sidebar.admin'
      ) {
        if (!isAdminRole) return true;
        return false;
      }
      if (
        (item.tnKey == 'menu.sidebar.users' ||
          item.tnKey == 'menu.sidebar.admin' ||
          item.tnKey == 'menu.sidebar.adminusers' ||
          item.tnKey == 'menu.sidebar.closedays' ||
          item.tnKey == 'menu.sidebar.settings') &&
        !isAdminRole // do not add, if its not admin
      ) {
        return false;
      }

      return true;
    });
  // ?.map((item) => {
  //   const currentItem = item;
  //   if (currentItem?.tnKey == 'menu.sidebar.materialbanktitle') {
  //     let chld = currentItem?.children;

  //     chld = chld?.filter((cl) => {
  //       if (cl?.tnKey == 'menu.sidebar.materialbank') {
  //         return isAdminRole;
  //       }
  //       return true;
  //     });

  //     currentItem.children = chld;
  //   }
  //   return currentItem;
  // });

  // console.log(isAdminRole);
  return (
    <VStack spacing={2} alignItems="flex-start">
      {filteredMenuItems.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </VStack>
  );
};

const SidebarIcons = ({ menuItems }) => (
  <VStack spacing={5} alignItems="flex-start">
    {menuItems.map((item, index) => {
      if (item.type === 'item') {
        return (
          item.icon && (
            <Icon as={FontAwesomeIcon} icon={item.icon} key={index} />
          )
        );
      }
      if (item.type === 'divider') {
        return <Divider borderColor="gray.200" key={index} />;
      }
      return '';
    })}
  </VStack>
);

const Sidebar = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const [isMdLg] = useMediaQuery(
    `(min-width: ${theme.breakpoints.md}) and (max-width: ${theme.breakpoints.lg})`
  );
  const [isHovered, setIsHovered] = useState(false);
  /*
  const [isMdLg, setIsMdLg] = useState(() => {
    const mediaQuery = window.matchMedia('(min-width: 48em) and (max-width: 64em)');
    return mediaQuery.matches;
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 48em) and (max-width: 64em)');

    const handleMediaQueryChange = (e) => {
      setIsMdLg(e.matches);
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);
  */

  return (
    <Box
      as="aside"
      bg="white"
      h={'calc(100vh - 80px)'}
      w="260px"
      minW={{ md: isHovered ? '260px' : '65px', lg: '260px' }}
      maxW={{ md: isHovered ? '260px' : '65px', lg: '260px' }}
      position="fixed"
      left={{ base: '0', lg: 'auto' }}
      top={{ base: '79px', md: '80px' }} // Adjusted top position
      boxShadow="lg"
      display={{ base: isOpen ? 'block' : 'none', md: 'block', lg: 'block' }}
      zIndex={999} // Ensure sidebar is above other content
      onMouseEnter={isMdLg ? () => setIsHovered(true) : undefined} // Handle hover state
      onMouseLeave={isMdLg ? () => setIsHovered(false) : undefined} // Handle hover state
      overflowY="auto"
      p="4"
    >
      <List>
        {(!isMdLg || isHovered) && <SidebarMenu menuItems={menuItems} />}
        {isMdLg && !isHovered && <SidebarIcons menuItems={menuItems} />}
      </List>
    </Box>
  );
};

const TopBar = ({ isOpen, onToggle, onNavToggle }) => (
  <Flex
    bg="white"
    color="black"
    alignItems="center"
    justifyContent="space-between"
    p="4"
    zIndex={1000} // Ensure top bar is above sidebar
    position="fixed"
    top="0"
    left="0"
    right="0"
    display={{ base: 'flex', md: 'none' }} // Display only on smallest screens
  >
    <IconButton
      aria-label="Open Sidebar"
      icon={isOpen ? <CloseIcon /> : <MenuIcon />}
      variant="ghost"
      colorScheme="white"
      onClick={onToggle}
    />
    <Flex>
      <Box>
        <Image
          src="https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-tunnus-sininen.png?1616571917"
          height="45"
          width="45"
          alt="Logo"
        />
      </Box>
      <Box ml="3">
        <Image
          src="https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-logo-sininen-pieni.png?1616571917"
          height="45"
          width="112"
          alt="Omapaja"
        />
      </Box>
    </Flex>
    <IconButton
      aria-label="More options"
      icon={<DotsMenuIcon />}
      variant="ghost"
      colorScheme="white"
      onClick={onNavToggle}
    />
  </Flex>
);

const MenuItem = ({
  children,
  isLast,
  to = '/dashboard',
  icon,
  ...rest
}: any) => (
  <Link href={to} ml={10} style={{ textDecoration: 'none' }}>
    <Flex alignItems="center">
      <Text display="block" {...rest} color="white">
        {children}
      </Text>
      {icon && <Icon as={FontAwesomeIcon} icon={icon} pl={2} />}
    </Flex>
  </Link>
);

const TopNav = ({ isOpen, onToggle }) => {
  const { t } = useTranslation();
  const { data: session } = useSession();
  const { roles } = useUserRoles();
  const { selectedRole, updateSelectedRole } = useSelectedUserRole();
  const { isAdminRole } = useIsAdminRole();

  const handleExitImpersonate = async () => {
    try {
      const response = await fetch('/api/impersonate', {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to drop impersonation ID');
      }

      const data = await response.json();
      window.location.reload();
    } catch (error) {
      console.error('Error updating impersonation:', error);
    }
  };

  return (
    <Flex
      bg={isAdminRole ? 'red.900' : 'blue.900'}
      color="white"
      alignItems="center"
      justifyContent="space-between"
      zIndex={1000} // Ensure top navigation is above sidebar
      position="fixed"
      w="100%"
      boxShadow="0 2px 4px var(--chakra-colors-blackAlpha-200)"
      // lineHeight="80px"
      top={{ base: '56px', md: '0' }} // Adjust top positioning to avoid overlap with TopBar on smallest screens
      display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }} // Display only on medium and large screens
    >
      <Link href="/dashboard">
        <Flex
          background={'white'}
          h="80px"
          w={{ base: 'none', md: 'flex', lg: 'flex' }}
          minW={{ base: 'none', lg: '260px' }}
          alignItems="center"
          justifyContent="center"
          mr="auto"
        >
          {/* Logo and brand images */}
          <Box
            display={{ base: 'none', md: 'flex' }}
            minW={{ base: '65px', lg: '0' }}
            justifyContent="center"
          >
            <Image
              src="https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-tunnus-sininen.png?1616571917"
              height="45"
              width="45"
              alt="Logo"
            />
          </Box>
          <Box display={{ base: 'none', lg: 'flex' }} ml="2">
            <Image
              src="https://omapajaportal.tndev.iggo.fi/img/adminmart/omapaja-logo-sininen-pieni.png?1616571917"
              height="45"
              width="112"
              alt="Omapaja"
            />
          </Box>
        </Flex>
      </Link>
      {session?.user.impersonationId ? (
        <>
          <Box>{session?.user?.impersonationId}</Box>
          <Box>
            <IconButton
              aria-label="Lopeta impersonointi"
              icon={<ExitImpersonate />}
              onClick={() => handleExitImpersonate()}
            />
          </Box>
        </>
      ) : (
        <></>
      )}

      <Flex
        spacing={8}
        flexGrow={1}
        justify="flex-end"
        mr={5}
        alignContent="space-evenly"
        alignItems="center"
      >
        {topMenuItems.map((item, index) => (
          <MenuItem key={index} icon={item.icon} to={item.path}>
            {t(item.tnKey)}
          </MenuItem>
        ))}
        {/* <MenuItem  isLast> */}
        <Box ml={10} display={'flex'}>
          <Popover>
            <PopoverTrigger>
              <Box as="button">
                <FaRegUserCircle width="16" height="16" />
              </Box>
            </PopoverTrigger>
            <PopoverContent width="250px" color="black">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody color="black" maxH="200px" overflowY="auto" py="4">
                <div>
                  <Box
                    as="label"
                    lineHeight={'unset'}
                    htmlFor="role-switch-select"
                  >
                    Switch role
                  </Box>
                  <Select
                    id="role-switch-select"
                    onChange={(e) => updateSelectedRole(e.target.value)}
                  >
                    {roles?.map((role) => {
                      return (
                        <option
                          key={role.id}
                          value={role.id}
                          selected={selectedRole?.id == role.id}
                        >
                          {role.display_name}
                        </option>
                      );
                    })}
                  </Select>
                </div>
              </PopoverBody>
              <PopoverFooter>
                <AuthButton />
              </PopoverFooter>
            </PopoverContent>
          </Popover>
        </Box>

        {/* <AuthButton /> */}
        {/* </MenuItem> */}
      </Flex>
    </Flex>
  );
};

const Layout = ({ children }) => {
  const router = useRouter();
  const { data: session, status } = useSession();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen: isNavOpen, onToggle: onNavToggle } = useDisclosure();

  if (status === 'loading') {
    return (
      <Center h={'100vh'}>
        <Text fontSize="xl">Loading...</Text>
      </Center>
    );
  }

  if (!session) {
    router.push('/');
    return null;
  }

  return (
    <Stack spacing="0">
      <TopBar isOpen={isOpen} onToggle={onToggle} onNavToggle={onNavToggle} />
      <TopNav isOpen={isNavOpen} onToggle={onToggle} />
      <Flex>
        <Sidebar isOpen={isOpen} onClose={onClose} />
        <Box
          as="main"
          mt="80px"
          ml={{ base: '0', md: '80px', lg: '260px' }}
          p="4"
          w="full"
          maxW={{
            base: 'full',
            md: 'calc(100vw - 90px)',
            lg: 'calc(100vw - 275px)',
          }}
        >
          {/* Main Content */}
          {children}

          <Divider p={5} borderColor={'black.500'} width={'auto'} />
          <FooterBar />
        </Box>
      </Flex>
    </Stack>
  );
};

export default Layout;
