import { userRolesObj } from '@/lib/getUserRoles';
import useSelectedUserRole from './useSelectedUserRole';
import { useEffect, useState } from 'react';

export default function useIsUserNormalRole() {
  const { selectedRole } = useSelectedUserRole();
  const [isUserNormalRole, setIsUserNormalRole] = useState(false);

  useEffect(() => {
    if (userRolesObj.kevytyrittäjä == selectedRole?.name) {
      setIsUserNormalRole(true);
    }
  }, [selectedRole]);

  return { isUserNormalRole };
}
